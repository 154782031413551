import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <section className="contact" id="contact">
            <h2>Contact Us</h2>
            <div className="contact-info">
                <div className="contact-item">
                    <h3>Phone</h3>
                    <p><a href="tel:+919080171806">+91 9080171806</a></p>
                </div>
                <div className="contact-item">
                    <h3>Address</h3>
                    <p>Erode, Tamil Nadu, India</p>
                </div>
            </div>
            <div className="contact-form">
                <h3>Get In Touch</h3>
                <form>
                    <input type="text" placeholder="Your Name" required />
                    <input type="email" placeholder="Your Email" required />
                    <textarea placeholder="Your Message" required></textarea>
                    <button type="submit">Send Message</button>
                </form>
            </div>
        </section>
    );
};

export default Contact;

import React from 'react';
import './Products.css';

const Products = () => {
    return (
        <section className="products" id="products">
            <h2>Our Products</h2>
            <div className="products-container">
                <div className="product-card">
                    <img src="c.jpg" alt="Powder Turmeric" />
                    <h3>Powder Turmeric</h3>
                    <p>
                        High-quality turmeric powder, finely ground to preserve all the natural 
                        benefits and flavor. Ideal for cooking and wellness.
                    </p>
                </div>
                <div className="product-card">
                    <img src="d.jpg" alt="Finger Turmeric" />
                    <h3>Finger Turmeric</h3>
                    <p>
                        Our finger turmeric is handpicked and carefully dried. Perfect for 
                        those seeking pure and authentic turmeric roots.
                    </p>
                </div>
                <div className="product-card">
                    <img src="e.jpg" alt="Round Turmeric" />
                    <h3>Round Turmeric</h3>
                    <p>
                        Round turmeric, also known as whole turmeric, retains the full integrity 
                        of the root. It's perfect for making fresh turmeric powder or using 
                        whole in various recipes.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Products;

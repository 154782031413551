// src/components/About.js
import React from 'react';
import './About.css';

const About = () => {
    return (
        <section id="about" className="about">
            <div className="about-content">
                <h2>About Us</h2>
                <p>
                    Welcome to Sea Quench, where we provide the finest quality turmeric sourced from organic farms. 
                    We are committed to delivering turmeric that meets international standards for taste, purity, and health benefits.
                </p>
                <p>
                    Our dedication to excellence and sustainable practices means we work directly with local farmers to bring you the best of nature's goodness. 
                    Join us on our journey to make high-quality turmeric available worldwide.
                </p>
            </div>
            <div className="about-image">
                <img src="a.jpeg" alt="Turmeric" />
            </div>
        </section>
    );
};

export default About;
